var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"GatewayManager"},[_c('UiList',[_vm._l((_vm.gateways),function(gateway,i){return _c('UiDrawer',{directives:[{name:"show",rawName:"v-show",value:(!gateway._deleting),expression:"!gateway._deleting"}],key:gateway.id,scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('UiItem',{staticClass:"ui-clickable",attrs:{"icon":"g:credit_card","text":gateway.name,"secondary":gateway.provider},on:{"click":function($event){return _vm.$emit('provider', gateway)},"delete":function($event){return _vm.deleteGateway(gateway)}}})]},proxy:true},{key:"contents",fn:function(ref){
var toggle = ref.toggle;
return [_c('GatewayEditor',{on:{"save":function($event){_vm.updateGateway(gateway).then(function () { return toggle(); })},"cancel":function($event){return toggle()},"input":function($event){return _vm.dataEmit($event)}},scopedSlots:_vm._u([{key:"data",fn:function(ref){
var data = ref.data;
var setData = ref.setData;
return [_vm._t("data",null,{"data":data,"setData":setData})]}}],null,true),model:{value:(_vm.gateways[i]),callback:function ($$v) {_vm.$set(_vm.gateways, i, $$v)},expression:"gateways[i]"}})]}}],null,true)})}),_c('UiDrawer',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var isOpen = ref.isOpen;
return [_c('UiItem',{staticClass:"ui-clickable",attrs:{"icon":isOpen ? 'g:close' : 'g:add_circle',"text":_vm.$t('GatewayManager.addGateway')}})]}},{key:"contents",fn:function(ref){
var toggle = ref.toggle;
return [_c('GatewayEditor',{on:{"save":function($event){_vm.saveNewGateway().then(function () { return toggle(); })},"cancel":function($event){_vm.resetNewGateway(); toggle()},"input":function($event){return _vm.dataEmit($event)}},scopedSlots:_vm._u([{key:"data",fn:function(ref){
var data = ref.data;
var setData = ref.setData;
return [_vm._t("data",null,{"data":data,"setData":setData})]}}],null,true),model:{value:(_vm.newGateway),callback:function ($$v) {_vm.newGateway=$$v},expression:"newGateway"}})]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }